import React from "react"
import styled from "styled-components"
import { v4 } from 'uuid'
import { Link } from "gatsby"
import { Section, SectionTitle, SectionDescription, SectionHeader, SectionPageTitle, MainPanel, MarkdownContent, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import DefaultButton from "../components/button/DefaultButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const OrderProcess = styled.div`
  margin:0 auto;
  max-width:1120px;
  width:100%;
`
const OrderProcessItem = styled.div`
  border:1px solid #333;
  border-radius:10px;
  background-color:#fff;
  padding:18px 20px;
  position:relative;
  margin:0 auto 80px auto;
  max-width:694px;
  ${BreakpointUp.sm`        
    display: flex;
    align-items: center;
  `}
  ${BreakpointUp.lg`        
    max-width:794px;
  `}
  ${BreakpointUp.xl`        
    max-width:894px;
  `}
  
  &:after{
    display: block;
    content: '';
    position: absolute;
    transform: translateX(-50%);
    width: 1px;
    height: 81px;
    background-color: #333;
    top: 100%;
    left: 50%;
  }
  &:last-child{
    margin-bottom:0;
    &:after{
      display:none;
    }
  }
`
const OrderProcessIcon = styled.div`
  min-width: 100px;
  margin-bottom:10px;
  ${BreakpointUp.sm`        
    padding:0 15px;   
    margin-bottom:0; 
  `}
  & img {
    width: 100px;      
    ${BreakpointUp.sm`        
      width: 150px;      
    `}
  }
`
const OrderProcessText = styled.div`
  ${BreakpointUp.sm`        
    padding:0 15px;    
  `}
  & h3{
    margin-bottom:0;
    font-size:20px;
    line-height:26px;
    font-family: 'Roboto', serif;
  }
  & p{
    margin-bottom:0;
  }
`
const SectionHeadText = styled.div` 
  text-align: ${props=>props.textAlign};
`
SectionHeadText.defaultProps = {
	textAlign: "center"
}

const OrderProcessPage = ({ data }) =>{
  const pageData = data.contentfulBuildingOrderProcess
  const PostContent = HTMLContent || Content;
  return(
    <Layout>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription}/>
        <SectionHeader pt="140px" pb="90px" xpt="140px" xpb="60px" bgColor="#F4F8FC">
          <div className="container">
            <BreadCrumb><Link to='/'>Home</Link>/<span>Estimated Delivery</span></BreadCrumb>         
            <SectionPageTitle mt="30px" mb="15px" textAlign="center">{pageData.title}</SectionPageTitle>
            <SectionDescription maxWidth="1120px" >
              <PostContent
                content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
              />
            </SectionDescription>       
          </div>
        </SectionHeader>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#fff">
            <div className="container">            
                <SectionTitle mb="20px">{pageData.processSectionTitle}</SectionTitle>
                <SectionDescription maxWidth="1120px" mb="40px">
              <PostContent
                content={generateHTML(pageData.processSectionDescription.childMarkdownRemark.html)}
              />
            </SectionDescription> 
                
                <OrderProcess>
                  {
                    pageData.orderProcess.map(item=>{
                      return(
                        <OrderProcessItem key={v4()}>
                          <OrderProcessIcon>
                            <img src={item.icon} alt={item.title} />
                          </OrderProcessIcon>
                          <OrderProcessText>
                            <h3>{item.process}</h3>
                            <p>{item.description}</p>
                          </OrderProcessText>
                        </OrderProcessItem>
                      )
                    })
                  }
                </OrderProcess>    
            </div>
        </Section>
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="#F6F6F6">
          <div className="container">
            <MainPanel>
                <MarkdownContent pl="15px" pr="15px" maxWidth="1120px">
                  <PostContent
                    content={generateHTML(pageData.content.childMarkdownRemark.html)}
                  />
                  <p><a href='tel:8773505464'><DefaultButton icon={<PhoneIcon />} text="(877) 350-5464" /></a></p>
                </MarkdownContent>          
            </MainPanel>
          </div>
        </Section>
      </Layout>
  )
}

export default OrderProcessPage

export const pageQuery = graphql`
  query OrderProcessPageQuery {
    contentfulBuildingOrderProcess {
      metaTitle
      metaDescription
      title
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      processSectionTitle
      processSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      orderProcess {
        process
        description
        icon
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`